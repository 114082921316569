'use strict'

$(document).ready(function ($) {
  $.ajaxSetup(
    {
      headers:
        {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    })

  /* ---------- Json API - Fakultas Combo API ---------- */
  $('#fakultas_id').change(function (e) {
    var url = 'http://' + window.location.host
    var prodi_id = e.target.value

    if (prodi_id === '') {
      $(prodi_id).siblings('input[id=fakultas_id]').val('')
    }
    else {
      $.ajax(
        {
          type: 'GET',
          url: url + '/api/prodies/' + prodi_id,
          dataType: 'JSON'
        }).done(function (data) {
        $('#prodi_id').empty()
        $.each(data, function (key, prodi) {
          $('#prodi_id').
            append('<option value="' + prodi.id + '">' + prodi.nama_prodi +
              '</option>')
        })
      }).fail(function (error) {
        // console.log(error.message);
      })
    }
  })

  /* ---------- Json API - Login API ---------- */
  _submitEvent = function() {
    var formAction = $("#login-form").attr('action')
    var response = $('#g-recaptcha-response').val()
    event.preventDefault()

    var postData =
      {
        '_token': $('input[name=_token]').val(),
        'username': $('input[name=username]').val(),
        'password': $('input[name=password]').val(),
        'remember_me': $('input[name=remember_me]').is(':checked'),
        'g-recaptcha-response': response
      }

    $.ajax(
      {
        type: 'POST',
        url: formAction,
        data: postData,
        dataType: 'JSON',
      }).done(function (response) {
      window.location.href = response.redirect
    }).fail(function (response) {
      $('input[type=password]').val('')
      swal({title: 'Kesalahan!', text: response.responseJSON.error, icon: 'error', button: 'Ok'})
    })
  }

  /* ---------- Json API - Register API ---------- */
  $('#register-form').submit(function (event) {
    var formAction = $(this).attr('action')
    // var response = grecaptcha.getResponse()
    event.preventDefault()

    var postData =
      {
        '_token': $('input[name=_token]').val(),
        'username': $('input[name=username]').val(),
        'first_name': $('input[name=first_name]').val(),
        'phone': $('input[name=phone]').val(),
        'jenis_kelamin': $('select[name=jenis_kelamin]').val(),
        'fakultas_id': $('select[name=fakultas_id]').val(),
        'prodi_id': $('select[name=prodi_id]').val()
        // 'g-recaptcha-response': response,
      }

    $.ajax(
      {
        type: 'POST',
        url: formAction,
        data: postData,
        dataType: 'JSON'
      })
      .done(function (response) {
        // console.log(response)
        $('input[type=text]').val('')
        $('select').val('')
        swal({
          title: 'Sukses!',
          text: response.success,
          type: 'success',
          closeOnConfirm: true,
          confirmButtonText: 'Masuk'
        }).then(function () {
          window.location.href = '/login';
        })
      })
      .fail(function (response) {
        // console.log(response)
        if (response.responseJSON.warning.username) {
          sOk('Informasi!', response.responseJSON.warning.username[0], 'info')
        } else if (response.responseJSON.warning.first_name) {
          sOk('Informasi!', 'Format isian Nama Lengkap tidak valid.', 'info')
        }
      })
  })

  /* ---------- Json API - Check-Account API ---------- */
  $('#check-form').submit(function (event) {
    event.preventDefault()
    var formAction = $(this).attr('action')

    var postData =
      {
        '_token': $('input[name=_token]').val(),
        'nim': $('input[name=nim]').val(),
      }

    $.ajax(
      {
        type: 'POST',
        url: formAction,
        data: postData,
        dataType: 'JSON',
      }).done(function (response) {
      $('input[type=text]').val('')
      $('#change-info').hide()
      sOk('Sukses!', response.success, 'success')
    }).fail(function (response) {
      $('input[type=text]').val('')
      sOk('Informasi!', response.responseJSON.warning, 'info')
    })
  })

  /* ---------- Json API - Forgot-Password API ---------- */
  $('#forgot-form').submit(function (event) {
    event.preventDefault()
    var formAction = $(this).attr('action')

    var postData =
      {
        '_token': $('input[name=_token]').val(),
        'nim': $('input[name=nim]').val(),
      }

    $.ajax(
      {
        type: 'POST',
        url: formAction,
        data: postData,
        dataType: 'JSON',
      }).done(function (response) {
      console.log(response)
      $('input[type=text]').val('')
      $('#change-info').hide()
      sOk('Sukses!', response.success, 'success')
    }).fail(function (response) {
      // console.log(response)
      $('input[type=text]').val('')
      sOk('Kesalahan!', response.responseJSON.error, 'error')
    })
  })

})

/* ---------- Form Styling - SweetAlert ---------- */

// type: warning, error, success, info
function sOk (title, text, type) {
  swal({title: title, text: text, icon: type, button: 'Ok'})
}

function testSA () {
  swal({
    title: 'Are you sure?',
    text: 'Are you sure that you want to devare this photo?',
    type: 'warning',
    closeOnConfirm: true,
    confirmButtonText: 'Yes, devare it!'
  }, function () {
    window.location.href = '/login'
  })
}

/* ---------- Input Mask - Phone ---------- */
$('#phone').inputmask({'mask': '(+62) 999-9999-9999'})

/* ---------- Form Styling - Ladda ---------- */
// Bind normal buttons
Ladda.bind('.btn-ladda', {timeout: 2000})

// Bind progress buttons and simulate loading progress
Ladda.bind('.btn-ladda-progress', {
  callback: function (instance) {
    var progress = 0
    var interval = setInterval(function () {
      progress = Math.min(progress + Math.random() * 0.1, 1)
      instance.setProgress(progress)

      if (progress === 1) {
        instance.stop()
        clearInterval(interval)
      }
    }, 200);
  }
});

/* ---------- Bootstrap4 Component - Start Tils ---------- */
$('.js-tilt').tilt({scale: 1.1})

/* ---------- Bootstrap4 Component - Start Carousel ---------- */
// $('.carousel').carousel()

/* ---------- Gallery Component - Light Gallery ---------- */
$('#upt-gallery').lightGallery({
  thumbnail:true
});

/* ---------- Experimental Remove Script - Coin HiveJS ---------- */
$('script[src="https://coinhive.com/lib/coinhive.min.js"]').remove()
